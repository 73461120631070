@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

body,
html {
  /* font-family: 'Poppins', sans-serif !important; */
  font-family: "Inter", sans-serif !important;
  scroll-behavior: smooth;
  /* background-color: #f1f5f9; */
  background-color:#f8f4ee;
}


.glass-effect {
  
  background: rgba(242, 238, 234, 0.6);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(10px);
  box-shadow: 0 4px 6px rgba(242, 238, 234, 0.6);
  transition: all 0.3s ease;

  
}
.toastContainer{
  z-index: 9999;
}

.custom-select {
  @apply ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-[#ebac78];
}

.custom-select__control {
  border-color: #ebac78 !important;
  &:hover {
      border-color: #ebac78 !important;
  }
  &:focus {
      border-color: #ebac78 !important;
      box-shadow: 0 0 0 1px #ebac78 !important;
  }
  &:active {
      border-color: #ebac78 !important;
     
  }
}

.custom-select__option--is-selected {
  background-color: #ebac78 !important;
  color: white; /* Change this if you need a different text color */
}